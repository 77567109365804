import * as React from 'react'

import { SEO } from '../../components/seo'

import { CoverImage } from '../../components/image'

type Props = {}

export default class StyleguidePage extends React.Component<Props, {}> {
  public render() {
    return (
      <>
        <SEO title="Styleguide" />
        <header className="container container--grid">
          <div className="container__row">
            <h1 className="type-h1">Images</h1>
          </div>
        </header>
        <section className="container container--grid">
          <div className="container__row">
            <h2 className="type-h2">CoverImage</h2>

            <h3 className="type-h3">Flex</h3>
            <div className="display-flex" style={{ maxWidth: 420 }}>
              <p style={{ flex: 1, paddingRight: 16 }}>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit
                provident ad, ab odio eius id nam dicta cum tempore quo amet
                repellat voluptatibus facere modi, sunt consequuntur placeat
                facilis eos.
              </p>
              <CoverImage
                style={{ flex: 1 }}
                alt="A nature image"
                src="https://source.unsplash.com/random/?space"
              />
            </div>
            <h3 className="type-h3">Fixed height</h3>
            <CoverImage
              style={{ height: 300 }}
              alt="A nature image"
              src="https://source.unsplash.com/random/?space"
            />
            <h3 className="type-h3">Aspect Ratio</h3>
            <CoverImage
              ratio={1 / 2}
              alt="A nature image"
              src="https://source.unsplash.com/random/?space"
            />
          </div>
        </section>
      </>
    )
  }
}
